import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';

import Icon from '../icon/icon';

import './cta.scss';

class Cta extends PureComponent {
  onClick = () => this.props.onClick({...this.props.args});

  render() {
    const {
      type,
      tag,
      text,
      link,
      bgColor,
      basic,
      icon,
      darkText,
      disabled,
      isExternalLink,
      loading,
      modifierClass,
      hasRef,
      month,
      TagBadge,
    } = this.props;

    const isWhiteBg = bgColor === "white";

    return (
      <div
        className={classNames("cta", {
          ["cta--white"]: isWhiteBg,
          ["cta--basic"]: basic,
          [`cta--icon cta--icon--${icon}`]: icon,
          ["cta--dark-text"]: darkText,
          [`cta--${modifierClass}`]: modifierClass,
          ["cta--loading"]: loading,
        })}
      >
        <div className="cta__inner">
          {tag === "button" && (
            <button
              disabled={disabled}
              type={type}
              {...(this.props.onClick && { onClick: this.onClick })}
            >
              <span className="cta__text">
                {text}
                {month && <small>{month}</small>}
              </span>
              {TagBadge && <div className="tagBadge">{TagBadge}</div>}
              {loading &&
                (isWhiteBg ? (
                  <Icon
                    modifierClass="spinner-cta"
                    iconUrl="/static/icons/spinner.svg"
                  />
                ) : (
                  <Icon
                    modifierClass="spinner-cta"
                    iconUrl="/static/icons/spinner-white.svg"
                  />
                ))}
            </button>
          )}
          {tag === "link" && (
            <>
              {isExternalLink ? (
                <a
                  target="_blank"
                  href={`${link}${hasRef ? "?ref=starthawk" : ""}`}
                >
                  {text}
                </a>
              ) : (
                <Link href={link}>
                  <a>
                    <span>{text}</span>
                    {loading &&
                      (isWhiteBg ? (
                        <Icon
                          modifierClass="spinner-cta"
                          iconUrl="/static/icons/spinner.svg"
                        />
                      ) : (
                        <Icon
                          modifierClass="spinner-cta"
                          iconUrl="/static/icons/spinner-white.svg"
                        />
                      ))}
                  </a>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
};

Cta.defaultProps = {
  disabled: false,
  loading: false
};

export default Cta;
